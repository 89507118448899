<template>
    <seccion-datos :titulo="contabilidad" tipo="success" @cambios="guardar(id)">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.codigoempresa') }}</label>
                    <input type="text" v-model="codigo_empresa" class="form-control  dato" >
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{ $t('general.prefijofacturas') }}</label>
                    <input type="text" v-model="prefijo_factura_empresa" class="form-control  dato">
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                    <label>{{ $t('general.subcuentaingresosgeneral') }}</label>
                    <input type="text" v-model="subcuenta_ingresos_general" class="form-control  dato" >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.subcuentagastogeneral') }}</label>
                    <input type="text" v-model="subcuenta_compras" class="form-control  dato">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.prefijoabonos') }}</label>
                    <input type="text" v-model="prefijo_abono_empresa" class="form-control  dato">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.numerodigitossubcuentas') }}</label>
                    <input type="text" v-model="digitos_subcuentas" class="form-control  dato" >
                </div>
            </div>
        </div>
        <div class="card card-success card-outline">                            
            <div class="card-body ">
                <h5>{{ $t('general.subcuentaiva') }}</h5>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm"  v-model="rentabilidad" >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">0%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm"  v-model="rentabilidad" >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">4%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm"  v-model="rentabilidad" >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">8%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm"  v-model="rentabilidad" >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">10%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm"  v-model="rentabilidad" >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">18%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm"  v-model="rentabilidad" >
                                <div class="input-group-append">
                                    <span class="input-group-text form-control-sm">21%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    data() {
        return {
            empresa: '',
            prefijo_factura_empresa: '',
            prefijo_abono_empresa: '',
            ruta_certificado: '',
            clave_certificado: '',
            subcuenta_compras: '',
            digitos_subcuentas:'',
            subcuenta_ingresos_general: '',
            codigo_empresa: '',
            seleccionado: '',
            contabilidad: '',
        }
    },
    methods: {

        async obtenerEmpresa() {
            const api = new PwgsApi;
            const datos = await api.get('empresa'); 
            this.empresa = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.prefijo_factura_empresa = this.empresa.prefijo_factura_empresa;
            this.prefijo_abono_empresa = this.empresa.prefijo_abono_empresa;
            this.ruta_certificado = this.empresa.ruta_certificado;
            this.digitos_subcuentas = this.empresa.digitos_subcuentas;
            this.clave_certificado = this.empresa.clave_certificado;
            this.subcuenta_compras = this.empresa.subcuenta_compras;
            this.subcuenta_ingresos_general = this.empresa.subcuenta_ingresos_general;
            this.codigo_empresa = this.empresa.codigo_empresa;
            this.$store.dispatch('cambiarCargando', false);
            this.contabilidad = this.$t('general.contabilidad');
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
        
            const subidadatos = { digitos_subcuentas: this.digitos_subcuentas, prefijo_factura_empresa: this.prefijo_factura_empresa, prefijo_abono_empresa: this.prefijo_abono_empresa, ruta_certificado: this.ruta_certificado, subcuenta_compras: this.subcuenta_compras, subcuenta_ingresos_general: this.subcuenta_ingresos_general, clave_certificado: this.clave_certificado, codigo_empresa: this.codigo_empresa };
            const api = new PwgsApi;
            try {
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('empresa', subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.obtenerEmpresa();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
    },
    mounted() {
        this.obtenerEmpresa();
    },
    watch: {
        id() {
            this.obtenerEmpresa();
        } 
    }
}
</script>